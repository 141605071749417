
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { loadStripe } from "@stripe/stripe-js";

import { PaymentsAppInitQueryParam } from "../../common/environment";
import { PaymentsAppReadyEvent } from "../../common/environment";
import { type CommandMessage, type IPaymentCurrency, type IPriceFormatResult, type ResultPayload, type Settings} from "../../common/interfaces";
import { ERROR_TYPE } from "../../common/labels";

async function settingsResolver() {
	const contextFileUrl = __LAUNCH_MODE__ === "Standard"
		? "/context.json"
		: `/settings/${__ENVIRONMENT__}-${__CHANNEL__}.json`;
	const response = await fetch(contextFileUrl, { method: "GET", cache: "no-cache" }).then((data) => data.json());
	return response as Settings;
}

const STRIPE_ERROR = "stripe-error";
const PAYMENT_FORM_ID = "payment-form";
const ERRORS_TO_HANDLE = ["processing_error"];

type StripePaymentError = "load-sdk" | "load-elements" | "create-elements" | "mount-elements" | "form-undefined" | "error-during-payment" | "amount-error" | "button-pay-error";

function priceFormatter(amount: number, options: IPaymentCurrency): IPriceFormatResult {
	const decimalSep = options.DecimalSep ?? ".";
	const decimalsDigitsOpt = options.DecDigits ? Math.abs(options.DecDigits) : 0;
	const decimalsDigits = isNaN(decimalsDigitsOpt) ? 0 : decimalsDigitsOpt;
	const thousandsSep = options.ThousandSep ?? "";
	const sign = amount < 0 ? "-" : "";
	const amountParsed = Math.abs(Number(amount) || 0).toFixed(decimalsDigits);
	const integerPart = parseInt(amountParsed);
	const integerPartString = integerPart.toString();
	const hasThousands = integerPartString.length > 3 ? integerPartString.length % 3 : 0;
	const hasDecimals = decimalsDigits > 0;
	const composition = {
		symbolBefore:
			options?.Symbol && options.SymbolBeforeValue ? options.Symbol : "",
		sign,
		thousands: hasThousands ? integerPartString.substr(0, hasThousands) : "",
		thousandsSeparator: hasThousands ? thousandsSep : "",
		integers: integerPartString
			.substr(hasThousands)
			.replace(/(\d{3})(?=\d)/g, "$1" + thousandsSep),
		decimals: hasDecimals
			? Math.abs(amount - integerPart)
				.toFixed(decimalsDigits)
				.split(".")[1]
			: "",
		decimalsSeparator: hasDecimals ? decimalSep : "",
		symbolAfter:
			options?.Symbol && !options.SymbolBeforeValue ? options.Symbol : ""
	};
	const result = (composition.symbolBefore ? composition.symbolBefore + " " : "")
		+ composition.sign
		+ (composition.thousands ? composition.thousands + composition.thousandsSeparator : "")
		+ composition.integers
		+ composition.decimalsSeparator
		+ composition.decimals
		+ (composition.symbolAfter ? " " + composition.symbolAfter : "");
	return { composition, result };
}


function redirectWithParams(code: string, message: string) {
	const Url = new URL(window.location.href);
	Url.search = "";
	Url.search = code + "=" + message;
	location.href = Url.toString();
}

export function showOverlay() {
	const loadingContent = document.getElementsByClassName("payment")[0];
	const formPayment = document.getElementById(PAYMENT_FORM_ID);
	if(loadingContent instanceof HTMLElement && formPayment){
		loadingContent.style.display = "block";
		formPayment.style.display = "none";
	}
}
export function hideOverlay() {
	const loadingContent = document.getElementsByClassName("payment")[0];
	const formPayment = document.getElementById(PAYMENT_FORM_ID);
	if(loadingContent instanceof HTMLElement && formPayment){
		loadingContent.style.display = "none";
		formPayment.style.display = "block";
	}
}


function onClosePopup() {
	window.close();
}

export async function stripeForm(paymentData: Extract<CommandMessage, { command: "stripe-payment" }>["payload"], targetElementId: string): Promise<void | StripePaymentError> {
	const contentHTML = `<form id=${PAYMENT_FORM_ID}>
						<div class="form-group">
							<div id="form-header">
								<img src="images/stripe_logo.png" alt="StripeLogo" height="90">
							</div>
							<div id="stripe-div"></div>
							<button type="submit" id="payment-button">
								<div
									id="spinner"
									class="spinner hidden"
								></div>
								<span id="button-text">PAY</span>
							</button>
							<div
								id="error-message"
							></div>
							<div
							<div id="close-button-container">
								<button id="close-button">Cancel Payment</button>
							</div>
						</div>
					</form>
					<div class="splash-screen payment" style="display: none">
						<span class="splash-content">
							<div class="splash-spinner">
								<div class="bounce1"></div>
								<div class="bounce2"></div>
								<div class="bounce3"></div>
							</div>
						</span>
					</div>`;
	document.body.innerHTML = contentHTML;

	document.getElementById("close-button")?.addEventListener("click", onClosePopup);

	const stripe = await loadStripe(paymentData.data.PublicKey, {stripeAccount: paymentData.data.StripeUserId});
	if(!stripe)
		return "load-sdk";

	const {result} = priceFormatter(paymentData.amount, paymentData.currency);
	const button = document.getElementById("button-text");

	if(!button)
		return "button-pay-error";

	button.innerText = `PAY ${result}`;

	const elements = stripe.elements({
		clientSecret: paymentData.data.ClientSecret,
		appearance: {
			theme: "stripe",
			variables: {
				colorPrimary: "#47a2a6",
				focusBoxShadow: "#47a2a6",
				iconColor: "#47a2a6",
				tabIconSelectedColor: "#47a2a6"
			}
		}
	});

	if(!elements)
		return "load-elements";

	const paymentElement = elements.create("payment");

	paymentElement.on("loaderror", function(event) {
		redirectWithParams(STRIPE_ERROR, "create-elements");
	});

	paymentElement.mount(`#${targetElementId}`);

	const form = document.getElementById(PAYMENT_FORM_ID);
	if(!form)
		return "form-undefined";

	//FIXME: [lib-payments] Implement Dynamic Language Switching in the Stripe Form: #68470

	form.addEventListener("submit", async(event) => {
		event.preventDefault();

		showOverlay();

		await elements.submit();

		const returnUrl = window.location.href.split("?")[0];
		const { error: confirmError} = await stripe!.confirmPayment({
			elements,
			confirmParams: {
				return_url: returnUrl,
			},
			clientSecret: paymentData.data.ClientSecret,
		});

		if (confirmError){
			hideOverlay();
			const messageContainer = document.querySelector("#error-message");
			if(messageContainer instanceof HTMLElement){
				messageContainer.textContent = "";
				if(confirmError.code && ERRORS_TO_HANDLE.includes(confirmError.code)){
					messageContainer.textContent = confirmError.message ?? "";
					messageContainer.style.color = "red";
				}
			}
		}

	});
}

function redirectForm(
	url: string,
	method: "GET" | "POST" | "PUT" | "PATCH" | "get" | "post" | "put" | "patch" = "POST",
	data?: object
) {
	if (method.toLowerCase() === "get" && !data)
		return location.replace(url);

	const form = document.createElement("form");
	form.style.display = "none";
	form.setAttribute("action", url);
	form.setAttribute("method", method.toLowerCase());
	let inputs = "";
	if (data) {
		Object.keys(data).forEach(field => {
			const value = (data as any)[field];
			inputs += `<input type="hidden" name="${field}" value="${value}" />`;
		});
		form.innerHTML = inputs;
	}
	document.body.appendChild(form);
	form.submit();
}

window.addEventListener("message", async(e) => {
	const data = e.data as CommandMessage;
	if (data.command === "redirect") {
		const { url, method, data } = (e.data as any).payload;
		redirectForm(url, method, data);
	} else if (data.command === "stripe-payment"){
		const formResult = await stripeForm(data.payload, "stripe-div");
		if(formResult)
			redirectWithParams(STRIPE_ERROR, formResult);
	}
});

window.addEventListener("load", async() => {
	const isInit = location.href.indexOf(`?${PaymentsAppInitQueryParam}=`) > 0;
	if (isInit) {
		const settings = await settingsResolver();
		if (settings.appInsightsConnString) {
			const AppInsights = new ApplicationInsights({
				config: {
					connectionString: settings.appInsightsConnString,
					disableExceptionTracking: true,
					disableTelemetry: __LAUNCH_MODE__ == "DevelopSession"
				}
			});
			AppInsights.loadAppInsights();
		}
		if (window.opener)
			window.opener.postMessage(PaymentsAppReadyEvent, "*");
		else
			window.parent?.postMessage(PaymentsAppReadyEvent, "*");
	} else {
		const params = new URLSearchParams(window.location.search);
		const parsedParams = Object.fromEntries(params.entries());
		const providerData = STRIPE_ERROR in parsedParams ? { name: ERROR_TYPE, code: parsedParams[STRIPE_ERROR], message: "Error occurred while initializing Stripe SDK" } : parsedParams;
		const result: ResultPayload = {
			providerReplied: true,
			providerData
		};

		if (window.opener)
			window.opener.postMessage(result, "*");
		else
			window.parent?.postMessage(result, "*");
	}
});
